<template>
  <div class="registration">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>

    <RegistrationStep
      :step="3"/>

    <section id="registration_message">
      <div class="container w1000">
        <h5 class="txt-bold txt-center h4">
          {{$t('registrationSuccess')}}
        </h5>

        <ul class="message_info">
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('registrationDate')}} |</span>
              <span>{{comoleteDataText.date}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('registrationTime')}} |</span>
              <span>{{comoleteDataText.time}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('registrationCategory')}} |</span>
              <span>{{comoleteDataText.devision}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('doctor')}} |</span>
              <span>{{comoleteDataText.doctor}} {{$t('doctor')}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('room')}} |</span>
              <!-- <span>{{comoleteDataText.place}}診</span> -->
              <span>{{placeConverter(comoleteDataText.place)}}{{$t('roomNum')}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('number')}} |</span>
              <span>{{comoleteDataText.num}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('yourName')}} |</span>
              <span>{{comoleteDataText.name}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span v-if="isTaiwanId" class="t txt-dark_gray">{{$t('uid')}} |</span>
              <span v-else class="t txt-dark_gray">{{$t('passportOrEntryPassNum')}} |</span>
              <span>{{comoleteDataText.account}}</span>
            </p>
          </li>
        </ul>
      </div>
      <p class="small txt-theme txt-center small_note">
        ．{{$t('comingInfo')}}
      </p>
    </section>

    <section id="registration_btnbox">
      <div class="w1000 box">
        <router-link to="/" class="btn btn-dark p">
          {{$t('backToIndex')}}
        </router-link>
        <button type="button" class="btn p" @click="toMemberHome();">
          {{$t('myregistration')}}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/scss/registration.scss';
import BannerKv from '@/components/BannerKv.vue';
import RegistrationStep from '@/components/registration/RegistrationStep.vue';

import { mapGetters, mapMutations } from 'vuex';
import { returnZhDay } from '@/lib/public';

import { isNationalIdentificationNumberValid } from 'taiwan-id-validator';

import pic01 from '@/assets/img/index/imglink_1.jpg';

export default {
  name: 'RegistrationComplete',
  components: {
    BannerKv,
    RegistrationStep,
  },
  data() {
    return {
      isTaiwanId: false,
      BannerKvData: {
        titleEn: 'ONLINE REGISTRATION',
        titleZh: this.$t('onlineBooking'),
        backgroundPic: pic01,
      },
      comoleteDataText: {
        date: null,
        time: null,
        devision: null,
        doctor: null,
        place: null,
        num: null,
        name: null,
        account: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getMemberAll',
      'getAppointmentData',
      'getAppointmentComplete',
    ]),
  },
  methods: {
    returnZhDay,
    ...mapMutations([
      'clearAppointmentData',
      'clearAppointmentComplete',
    ]),
    placeConverter(doctorClinic) {
      switch (doctorClinic) {
        case '一診':
          return this.$t('one');
        case '二診':
          return this.$t('two');
        case '三診':
          return this.$t('three');
        case '四診':
          return this.$t('four');
        case '五診':
          return this.$t('five');
        case '六診':
          return this.$t('sex');
        case '七診':
          return this.$t('seven');
        case '八診':
          return this.$t('eight');
        case '大安一診':
          return '大安一';
        default:
          return doctorClinic;
      }
    },
    initComoleteDataText() {
      if (
        this.getAppointmentData.devision_id
        && this.getAppointmentData.appointment_day
        && this.getAppointmentData.shift_code
        && this.getAppointmentData.clinic
        && this.getAppointmentData.doctor_name
        && this.getAppointmentComplete.serial_no
      ) {
        const timeZH = (condition) => {
          let temp = '';
          switch (condition) {
            case '1':
              temp = this.$t('am');
              break;
            case '3':
              temp = this.$t('pm');
              break;
            case '4':
              temp = this.$t('betweenNight');
              break;
            default:
              temp = null;
              break;
          }
          return temp;
        };
        const devisionZH = (condition) => {
          let temp = '';
          switch (condition) {
            case '1':
              temp = this.$t('ivf');
              break;
            case '2':
              temp = this.$t('tcm');
              break;
            case '3':
              temp = this.$t('ivf');
              break;
            default:
              temp = null;
              break;
          }
          return temp;
        };
        // const clinicZH = (condition) => {
        //   let temp = '';
        //   switch (condition) {
        //     case '1':
        //       temp = this.$t('one');
        //       break;
        //     case '2':
        //       temp = this.$t('two');
        //       break;
        //     case '3':
        //       temp = this.$t('three');
        //       break;
        //     case '4':
        //       temp = this.$t('four');
        //       break;
        //     case '5':
        //       temp = this.$t('five');
        //       break;
        //     case '6':
        //       temp = this.$t('sex');
        //       break;
        //     case '7':
        //       temp = this.$t('seven');
        //       break;
        //     case '8':
        //       temp = this.$t('eight');
        //       break;
        //     default:
        //       temp = null;
        //       break;
        //   }
        //   return temp;
        // };
        this.comoleteDataText.date = `${this.getAppointmentData.appointment_day} (${this.returnZhDay(new Date(this.getAppointmentData.appointment_day.replace(/-/g, '/')))})`;
        console.log('this.getAppointmentData.appointment_day:', this.getAppointmentData.appointment_day);
        this.comoleteDataText.time = timeZH(this.getAppointmentData.shift_code);
        this.comoleteDataText.devision = devisionZH(this.getAppointmentData.devision_id);
        this.comoleteDataText.doctor = this.getAppointmentData.doctor_name;
        this.comoleteDataText.place = this.getAppointmentData.clinic;
        this.comoleteDataText.num = this.getAppointmentComplete.serial_no;
        this.comoleteDataText.name = this.getMemberAll.name;
        this.comoleteDataText.account = this.getMemberAll.account;
        if (isNationalIdentificationNumberValid(this.getMemberAll.account)) {
          this.isTaiwanId = true;
        }
      } else {
        this.$customSWAL(
          {
            icon: 'error',
            title: this.$t('dataError'),
            thenFunc: () => {
              this.$router.push('/MemberHome');
            },
          },
        );
      }
    },
    toMemberHome() {
      this.clearAppointmentData();
      this.clearAppointmentComplete();
      this.$router.push('/MemberHome');
    },
    showMessage() {
      const day = new Date(this.getAppointmentData.appointment_day).getUTCDay();
      if (
        this.getMemberAll.is_first === 'Y'
        && this.getAppointmentData.doctor_code === 'D01'
      ) {
        if (
          this.getAppointmentData.shift_code === '1'
          && day > 0
          && day <= 5
        ) {
          this.$customSWAL({
            icon: 'info',
            title: '初診患者<br>請依照下列時間報到',
            html: `週一至星期五早診<br>
              初診報到：10:00-11:30`,
          });
        } else if (
          this.getAppointmentData.shift_code === '4'
          && day > 0
          && day <= 4
        ) {
          this.$customSWAL({
            icon: 'info',
            title: '初診患者<br>請依照下列時間報到',
            html: `週一及週四夜診<br>
              初診報到：19:00-20:30`,
          });
        } else if (
          this.getAppointmentData.shift_code === '1'
          && day === 6
        ) {
          this.$customSWAL({
            icon: 'info',
            title: '初診患者<br>請依照下列時間報到',
            html: `週六早診<br>
              初診報到：11:30-12:30`,
          });
        }
      }
      console.log(
        day,
        this.getMemberAll.is_first,
        this.getAppointmentData.doctor_code,
        this.getAppointmentData.shift_code,
        this.getMemberAll.is_first === 'Y',
        this.getAppointmentData.doctor_code === 'D01',
        this.getAppointmentData.shift_code === '2',
      );
      console.log('getMemberAll', this.getMemberAll);
      console.log('getAppointmentData', this.getAppointmentData);
      console.log('getAppointmentComplete', this.getAppointmentComplete);
    },
  },
  mounted() {
    this.initComoleteDataText();
    this.showMessage();
  },
  updated() {
    this.initComoleteDataText();
  },
};
</script>
